import '../styles/global.scss';
import '../styles/pages/onboard.scss';

import {HeadFC} from "gatsby";

import Slider from "react-slick";
import React, {useEffect, useState, useMemo} from 'react';
import parse from 'html-react-parser';
import {Link, navigate} from "gatsby";

import {
    logoOnBoard,
    aeerSlide1,
    aeerSlide2,
    aeerSlide3,
    aeerSlide4,
    mobileLogin,
    dotsMore,
    arrowLeft,
    firstModule,
    crownYellow,
    advaStep1,
    advaStep2,
    advaStep3,
    checkCircle2,
    bell,
    clockCircle,
    infoCircle,
    girlTrial,
    onBoardCheck
} from "../images";
import useAnalytics from "../hooks/useAnalytics";
import OnBoardQuestion from '../components/OnBoardQuestion';
import OnBoardReg from '../components/OnBoardReg';
import OnBoardLoading from '../components/OnBoardLoading';
import OnBoardPayment from '../components/OnBoardPayment';
import OnBoardFinish from '../components/OnBoardFinish';
import OnBoardRegCorp from '../components/OnBoardRegCorp';
import SpecialRegCorp from '../components/SpecialRegCorp';
import SpecialLoading from '../components/SpecialLoading';
import SpecialPayment from '../components/SpecialPayment';

type Props = {
    id: string,
}

const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const CorpMainSpecialIlyaPage = ({id}: Props) => {
    const {initAnalytics, saveEmail, saveSubType} = useAnalytics();

    useEffect(() => {
        initAnalytics();
        console.log('Trigger event');
    }, []);

    const [screenShots, setScreenShots] = useState([
        {
            id: 1,
            image: aeerSlide1,
            title: 'Reach your full life potential with AEER',
            text: 'Develop your life in your own way. Create your life script. Make it come true with us.',
        },
        {
            id: 2,
            image: aeerSlide2,
            title: 'Develop your Soft Skills',
            text: 'Our interactive courses do not just provide information but show the way to apply knowledge in life.',
        },
        {
            id: 3,
            image: aeerSlide3,
            title: 'Control your finances so easy',
            text: 'Track all your income and expenses, plan and increase them by identifying all financial leaks.',
        },
        {
            id: 4,
            image: aeerSlide4,
            title: 'Manage your "To-Do List"',
            text: 'Set goals and achieve them. <br /> We will help you stay on track!',
        },
    ]);

    const [questions, setQuestions] = useState([
        {
            id: 1,
            name: 'quiz-how-to-call',
            type: 'field',
            title: "What would you like us to call you?",
            answers: [],
            next: '/corp-main-special-ilya-google/already-used-services',
        },
        {
            id: 2,
            name: 'already-used-services',
            type: 'radio',
            title: "Have you already used any services for education and personal productivity?",
            answers: [
                'Yes, I have used',
                'I have never tried'
            ],
            next: '/corp-main-special-ilya-google/select-age',
        },
        {
            id: 3,
            name: 'select-age',
            type: 'radio',
            title: "What is your age?",
            answers: [
                '13-17',
                '18-24',
                '25-34',
                '35-44',
                '45-54',
                '55+'
            ],
            next: '/corp-main-special-ilya-google/your-workstyle',
        },
        {
            id: 4,
            name: 'your-workstyle',
            type: 'radio',
            title: "What is your workstyle?",
            answers: [
                'I feel comfortable in structure and prefer to plan everything in advance',
                'I prefer to work with a burst of energy and don`t limit myself with structure and deadlines'
            ],
            next: '/corp-main-special-ilya-google/financial-habits',
        },
        {
            id: 5,
            name: 'financial-habits',
            type: 'radio',
            title: "Do your financial habits enable you to stick to your goals?",
            answers: [
                'Yes, I do',
                'No, I want to embrace my financial awareness'
            ],
            next: '/corp-main-special-ilya-google/what-to-achive',
        },
        {
            id: 6,
            name: 'what-to-achive',
            type: 'checkbox',
            title: "What do you want to achieve with AEER?",
            answers: [
                'Become more structured and productive. Stop postponing tasks',
                'develop good habits and achieve long-therm goals',
                'time-management and stress-free lifestyle',
                'get some meta skills',
                'work effectively without procrastination and burnouts'
            ],
            next: '/corp-main-special-ilya-google/what-prevent-you',
        },
        {
            id: 7,
            name: 'what-prevent-you',
            type: 'checkbox',
            title: "What prevents you from achieving the desired results?",
            answers: [
                'Procrastination',
                'I am afraid of failure',
                'I perform better under pressure',
                'I get distracted easily'
            ],
            next: '/corp-main-special-ilya-google/where-do-we-start',
        },
        {
            id: 8,
            name: 'where-do-we-start',
            type: 'radio',
            title: "Where do we start?",
            answers: [
                'Choose minicourse for developing meta skills',
                'Create workflow to get things done efficiently',
                'Arrange my finances',
                'Create step-by-step plan for achieving goals'
            ],
            next: '/corp-main-special-ilya-google/register-email',
        },
    ]);

    const [tarif, setTarif] = useState('year');
    const [isFinish, setIsFinish] = useState(false);

    const chooseTarif = (tarifName:string) => {
        setTarif(tarifName);
    }

    const confirmTarif = () => {
        if(tarif === 'year'){
            // localStorage.setItem('onBoardTarif', 'price_1MieprGxzah9dg4QKRqAlQSZ');
            localStorage.setItem('onBoardTarif', 'price_1Mo34fGxzah9dg4QhAknp5XF');
        } else if(tarif === 'month'){
            // localStorage.setItem('onBoardTarif', 'price_1MieqCGxzah9dg4Qklc5aEPW');
            localStorage.setItem('onBoardTarif', 'price_1Mo35EGxzah9dg4QQw1E5JmX');
        }
    }

    const onSuccessPayment = () => {
        setIsFinish(true)
    }

    const renderNavigation = () => {
        return <div className="onBoardNavigation">
            <button className="onBoardPrev" onClick={() => navigate(-1)}>
                <img src={arrowLeft} alt="" />
            </button>
            {/* <button className="onBoardDotsMore">
                <img src={dotsMore} alt="" />
            </button> */}
        </div>
    };

    const renderQuestionPage = (name:string) => {
        return questions.map( (key) => {
            if(key.name === name){
                return (
                    <React.Fragment key={`onboard-${key.id}`}>
                        {renderNavigation()}
                        <OnBoardQuestion  data={key} array={questions} />
                    </React.Fragment>
                );
            }
        })
    }
    
    const renderList = useMemo(() => {
        return screenShots.map((screenshot, index) => {
            return (
                <div className="onBoardSliderItem" key={`slide-${screenshot.id}`}>
                    <div 
                        className="onBoardSliderImage" style={{backgroundImage: `url(${screenshot.image})`}}></div>
                    <div className="onBoardSliderInfo">
                        <span className="onBoardSliderTitle">{parse(screenshot.title)}</span>
                        <p className="onBoardSliderText">{parse(screenshot.text)}</p>
                    </div>
                </div>
            )
        })
    }, [screenShots]);

    const renderPages = () => {
        switch(id) {
            case 'main':
                return <div className="onBoardMain">
                    <div className="onBoardTop">
                        <img src={logoOnBoard} alt="" className="onBoardLogo" />
                        <Slider initialSlide={0} {...settings} className="onBoardSlider">
                            {renderList}
                        </Slider>
                    </div>
                    <div className="onBoardBottom">
                        <Link to="/corp-main-special-ilya-google/sign-up" className="onBoardBtn">Get Started</Link>
                        {/* <p className="onBoardLogin">
                            Already have an account: 
                            <a href=""> login</a>
                        </p> */}
                    </div>
                </div>;
            case 'sign-up':
                return <div className="onBoardBox">
                    <div className="onBoardSign">
                        <img src={mobileLogin} className="onBoardSignVector" alt="" />
                        <p className="onBoardSignTitle">How would you like to sign up ?</p>
                        <p className="onBoardPrivacy">
                            By using AEER Platform you agree with our <br /> 
                            <Link to="/privacy">Privacy & Policy</Link> and <Link to="/terms">Terms of use</Link>
                        </p>
                        {/* <div className="onBoardSignBtns">
                            <Link to="/corp-main-special-ilya-google/quiz-how-to-call" className="onBoardBtn">As an individual</Link>
                            <Link to="/corp-main-special-ilya-google/quiz-how-to-call" className="onBoardBtn grey mt">Through an employer</Link>
                        </div> */}
                    </div>
                    <div className="onBoardBottom">
                        <Link to="/corp-main-special-ilya-google/quiz-how-to-call" className="onBoardBtn">As an individual</Link>
                        <Link to="/corp-main-special-ilya-google/quiz-how-to-call" className="onBoardBtn grey mt">Through an employer</Link>
                    </div>
                </div>;
            case 'quiz-how-to-call':
                return <div className="onBoardBox">
                    {renderQuestionPage('quiz-how-to-call')}
                </div>;
            case 'already-used-services':
                return <div className="onBoardBox">
                    {renderQuestionPage('already-used-services')}
                </div>;
            case 'select-age':
                return <div className="onBoardBox">
                    {renderQuestionPage('select-age')}
                </div>;
            case 'your-workstyle':
                return <div className="onBoardBox">
                    {renderQuestionPage('your-workstyle')}
                </div>;
            case 'financial-habits':
                return <div className="onBoardBox">
                    {renderQuestionPage('financial-habits')}
                </div>;
            case 'what-to-achive':
                return <div className="onBoardBox">
                    {renderQuestionPage('what-to-achive')}
                </div>;
            case 'what-prevent-you':
                return <div className="onBoardBox">
                    {renderQuestionPage('what-prevent-you')}
                </div>;
            case 'where-do-we-start':
                return <div className="onBoardBox">
                    {renderQuestionPage('where-do-we-start')}
                </div>;
            case 'first-module':
                return <div className="onBoardBox">
                    <div className="onBoardNavigation">
                        <button className="onBoardPrev" onClick={() => navigate(-1)}>
                            <img src={arrowLeft} alt="" />
                        </button>
                        {/* <button className="onBoardDotsMore">
                            <img src={dotsMore} alt="" />
                        </button> */}
                    </div>
                    <div className="onBoardModule">
                        <img src={logoOnBoard} alt="" className="onBoardLogo" />
                        <p className="onBoardModuleTitle">We matched you with your first <br /> learning module</p>
                        <img src={firstModule} alt="" className="onBoardModuleVector" />
                    </div>
                    <div className="onBoardBottom">
                        <Link to="/corp-main-special-ilya-google/actionable-tools" className="onBoardBtn">Continue</Link>
                    </div>
                </div>;
            case 'actionable-tools':
                return <div className="onBoardBox">
                    <div className="onBoardNavigation">
                        <button className="onBoardPrev" onClick={() => navigate(-1)}>
                            <img src={arrowLeft} alt="" />
                        </button>
                        {/* <button className="onBoardDotsMore">
                            <img src={dotsMore} alt="" />
                        </button> */}
                    </div>
                    <div className="onBoardModule">
                        <img src={logoOnBoard} alt="" className="onBoardLogo" />
                        <img src={firstModule} alt="" className="onBoardModuleVector" />
                        <p className="onBoardModuleTitle mt mb">Success with AEER Platform <br /> Gives you actionable tools to:</p>
                        <div className="onBoardModuleAdvas">
                            <div className="onBoardModuleAdva">
                                <img src={crownYellow} alt="" />
                                <span>Live to your fullest potential</span>
                            </div>
                            <div className="onBoardModuleAdva">
                                <img src={crownYellow} alt="" />
                                <span>Overcome negative emotions</span>
                            </div>
                            <div className="onBoardModuleAdva">
                                <img src={crownYellow} alt="" />
                                <span>Feel more focused</span>
                            </div>
                        </div>
                    </div>
                    <div className="onBoardBottom">
                        <Link to="/corp-main-special-ilya-google/register-email" className="onBoardBtn">Continue</Link>
                    </div>
                </div>;
            case 'register-email':
                return <div className="onBoardBox">
                    {renderNavigation()}
                    <SpecialRegCorp id={id} type='ilya-google' fullLink="corp-main-special-ilya-google" />
                </div>
            case 'confirm-email':
                return <div className="onBoardBox">
                    {renderNavigation()}
                    <OnBoardReg id={id} />
                </div>
            case 'create-username':
                return <div className="onBoardBox">
                    {renderNavigation()}
                    <OnBoardReg id={id} />
                </div>
            case 'create-password':
                return <div className="onBoardBox">
                    {renderNavigation()}
                    <OnBoardReg id={id} />
                </div>
            case 'loading-data':
                return <div className="onBoardBox loadcenter">
                    <SpecialLoading setLink={'/corp-main-special-ilya-google/what-you-get'} />
                </div>
            case 'what-you-get':
                return <div className="onBoardBox">
                    <p className="onBoardAdvasTitle mt">Here’s what you get</p>
                    <div className="onBoardAdvas">
                        <div className="onBoardAdva">
                            <div className="onBoardAdvaIcon">
                                <img src={advaStep3} alt="" />
                            </div>
                            <p className="onBoardAdvaSubtitle">
                                <span>Monitoring your finances</span>
                            </p>
                            <p className="onBoardAdvaText">Work directly with one of our specialist <br /> coaches to help you reach</p>
                        </div>
                        <div className="onBoardAdva">
                            <div className="onBoardAdvaIcon">
                                <img src={advaStep1} alt="" />
                            </div>
                            <p className="onBoardAdvaSubtitle">
                                <span>New tools and habit formation</span>
                            </p>
                            <p className="onBoardAdvaText">They will put you one step ahead of the rest in everything you do.</p>
                        </div>
                        <div className="onBoardAdva">
                            <div className="onBoardAdvaIcon">
                                <img src={advaStep2} alt="" />
                            </div>
                            <p className="onBoardAdvaSubtitle">
                                <span>Understanding your targets</span>
                            </p>
                            <p className="onBoardAdvaText">Once you have clarified the goal, you can break it down into manageable pieces. We will help you on your way.</p>
                        </div>
                        
                    </div>
                    <div className="onBoardBottom">
                        <Link to='/corp-main-special-ilya-google/try-trial' className="onBoardBtn">Next</Link>
                    </div>
                </div>
            case 'try-trial':
                return <div className="onBoardBox">
                    {renderNavigation()}
                    <p className="onBoardAdvasTitle">Try AEER Platform in 7-days <br /> free trial</p>
                    <div className="onBoardSteps">
                        <div className="onBoardStep">
                            <div className="onBoardStepIcon darkline">
                                <img src={checkCircle2} alt="" />
                            </div>
                            <span className="onBoardStepTitle">Today</span>
                            <p className="onBoardStepSubtitle">No payment now. Enjoy full access to <br /> AEER Platform for free</p>
                        </div>
                        <div className="onBoardStep">
                            <div className="onBoardStepIcon solidline">
                                <img src={bell} alt="" />
                            </div>
                            <span className="onBoardStepTitle">Day 5</span>
                            <p className="onBoardStepSubtitle">Get a reminder about when your free trial <br /> will end</p>
                        </div>
                        <div className="onBoardStep">
                            <div className="onBoardStepIcon dashline">
                                <img src={clockCircle} alt="" />
                            </div>
                            <span className="onBoardStepTitle">Day 7</span>
                            <p className="onBoardStepSubtitle">On this day your trial will convert to full price <br /> unless cancelled </p>
                        </div>
                        <div className="onBoardStep">
                            <div className="onBoardStepIcon">
                                <img src={infoCircle} alt="" />
                            </div>
                            <span className="onBoardStepTitle">Don’t worry - we’ll refund you if you forget!</span>
                        </div>
                    </div>
                    
                    <div className="onBoardBottom">
                        <Link to="/corp-main-special-ilya-google/trial-tarif" className="onBoardBtn">i’m in!</Link>
                    </div>
                </div>
            case 'trial-tarif':
                return <div className="onBoardBox">
                    {renderNavigation()}
                    <p className="onBoardAdvasTitle">Try AEER Platform in 7-days <br /> free trial</p>
                    <div className="onBoardRemark">
                        We’ll <b>remind</b> you before your trial end, and <br /> <b>refund</b> you if you forget to cancel
                    </div>
                    <div className="onBoardTarif">
                        <div 
                            className={`onBoardTarifItem ${(tarif === 'year') ? 'active' : ''}`} 
                            onClick={() => chooseTarif('year')}>
                            <div className="onBoardTarifIcon">
                                <img src={onBoardCheck} alt="" />
                            </div>
                            <p className="onBoardTarifTitle">$0.00 and after trial $39.90/3 months</p>
                            <p className="onBoardTarifText">Charge every three months after 7 days trial</p>
                            <div className="onBoardTarifBest">Best value</div>
                        </div>
                        <div 
                            className={`onBoardTarifItem ${(tarif === 'month') ? 'active' : ''}`} 
                            onClick={() => chooseTarif('month')}>
                            <div className="onBoardTarifIcon">
                                <img src={onBoardCheck} alt="" />
                            </div>
                            <p className="onBoardTarifTitle">$0.00 and after trial $19.90/month</p>
                            <p className="onBoardTarifText">Charge monthly after 7 day free trial</p>
                        </div>
                    </div>
                    <div className="onBoardComment">
                        <div className="onBoardCommentInside">
                            <div className="onBoardCommentIcon">
                                <img src={girlTrial} alt="" />
                            </div>
                            <p className="onBoardCommentTitle">Sam Miller</p>
                            <p className="onBoardCommentText">“I have been using AEER for a month now. Breaking the habits that have always led to procrastination, failure, and dissatisfaction with life is hard work. But with such a selection of tools, this is easier. Now I know what to do and nothing can stop me on the way to my goal. I begin to live in a new reality.”</p>
                        </div>
                    </div>
                    <div className="onBoardBottom">
                        <Link to="/corp-main-special-ilya-google/onboard-payment" onClick={() => confirmTarif()} className="onBoardBtn">Start free trial</Link>
                    </div>
                </div>
            case 'onboard-payment':
                return <div className="onBoardBox">
                    {renderNavigation()}
                    <SpecialPayment tarifName={tarif} type='ilya-google' redirectLink="/solutions/finish" onSuccess={onSuccessPayment} />
                </div>
            case 'finish-onboard':
                return <div className="onBoardBox">
                    {renderNavigation()}
                    <OnBoardFinish id={id} isFinish={isFinish} />
                </div>
            default:
                return <div>This page doesn`t exist</div>;
        }
    }

    return (
        <>
            <div className="onBoard">
                {renderPages()}
            </div>
        </>
    )
}

export default CorpMainSpecialIlyaPage;

export const Head: HeadFC = () => (
    <>
        <title>Quiz - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);
